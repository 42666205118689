@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

:root {

    /* COLORES */

    --black-color: #181818;
    --white-color: #FAFAFA;
    --orange-primary-color: #FFAC1C;
    --orange-secondary-color: #FFC534; 
    --pink-primary-color: #FF38BD;
    --pink-secondary-color: #D33090;
    --background-grey-color: #D5D5D5;
    --background-grey-color-secondary: #F1F1F1;
    --dark-font-color: #434343;
    --shadow-color: #888888;

    /* TAMAÑOS */
    --nav-height: 13vh;
    --nav__compresed-height: 10vh;

    --container-padding: 1rem 13rem 1rem 13rem;

    /* FONT-SIZES */
    --title-size: 40px;
    --subtitle-size: 20px;
    --title-paragraph-size: 19px;
    --paragraph-size: 18px;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
}

body {
    background-color: #F1F1F1;
}

.clickeable-item {
    color: var(--dark-font-color);
    transition: 0.5s;
    cursor: pointer;
}

.clickeable-item:hover {
    color: var(--orange-primary-color);
    transition: 0.2s;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 23px;
}

p {
    opacity: 0.8;
    font-size: 20px;
}

.title {
    font-family: 'Koulen', cursive;
    -webkit-font-smoothing: antialiased;
    opacity: 0.9;
}

.subtitle {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    opacity: 0.8;
}

.paragraph {
    font-family: 'Montserrat', sans-serif;
}

@media (min-width: 500px) and (max-width: 800px) {
    :root {
        --container-padding: 1rem 3rem 1rem 3rem;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 15px;
    }
    h4 {
        font-size: 15px;
    }
    p {
        font-size: 10px;
    }
}

@media (max-width: 500px) {
    :root {
        --container-padding: 1rem 3rem 1rem 3rem;
    }
    h2 {
        font-size: 15px;
    }
    h3 {
        font-size: 10px;
    }
    h4 {
        font-size: 9px;
    }
    p {
        font-size: 5px;
    }
}